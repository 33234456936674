import { createContext, useContext, useEffect } from 'react';
import { LocaleContext } from '123rf-ui-core/contexts';
import { parseCookies } from 'nookies';
import PropTypes from 'prop-types';

// Pre-defined Components
import { DEFAULT_IP_ADDRESS, PAGE_ORIGINS, VALID_PAGE_ORIGINS as validPOs } from 'config/constants';
import {
    CHECKOUT_URL,
    MY_REGION,
    SELECTED_CYP_MEDIA_ID as SCMI,
    SELECTED_IMAGE_DETAIL_COOKIES as SID,
    SELECTED_PLUS_IMAGE_DETAIL_COOKIES as SPID,
    PLAN,
} from 'config/cookies';
import { PLUS_PLANS, PRODUCT_TYPES } from 'config/payments';
import createCookie from 'helpers/cookies/createCookie';
import removeLocalStorageItem from 'helpers/local_storage/removeLocalStorageItem';
import setLocalStorageItem from 'helpers/local_storage/setLocalStorageItem';

export const CommonDetailsContext = createContext();

export default function useCommonDetails(props) {
    const { router } = props;
    const cookies = parseCookies();
    const localeDetails = useContext(LocaleContext) || {};
    const locale = {
        ...localeDetails,
        lang: props?.lang,
        ipAddress: props?.clientIPAddress || DEFAULT_IP_ADDRESS,
    };
    const myRegion = cookies[MY_REGION] || '';
    !myRegion &&
        createCookie(
            {},
            MY_REGION,
            `${localeDetails?.country?.code}|${localeDetails?.country?.description}`,
            60 * 60 * 24 * 14,
        );
    const pkg = router?.query?.pkg || props?.pkg || '';
    const plan = router?.query?.plan || props?.plan || cookies[PLAN] || '';
    const autorenew = router?.query?.autorenew || 0;
    const upgrade = router?.query?.upgrade || 0;
    const addon = router?.query?.addon || 0;
    const proforma = router?.query?.proforma || props?.proforma;
    const termsgrant = router?.query?.termsgrant || props?.termsgrant || '';
    const termtype = router?.query?.termtype || props?.termtype || '';
    const transid = router?.query?.transid || props?.transid || '';
    const pageOrigin =
        router?.query?.po ||
        props?.pageOrigin ||
        (proforma || (termsgrant && termtype) ? PAGE_ORIGINS.pricing : '');
    const productType = proforma
        ? PRODUCT_TYPES.proforma
        : termsgrant && termtype
        ? PRODUCT_TYPES.termsgrant
        : pkg &&
          pkg !== PRODUCT_TYPES.multilogin &&
          plan &&
          pageOrigin &&
          validPOs.includes(pageOrigin)
        ? PRODUCT_TYPES.default
        : pkg === PRODUCT_TYPES.multilogin &&
          transid &&
          plan &&
          pageOrigin &&
          validPOs.includes(pageOrigin)
        ? PRODUCT_TYPES.multilogin
        : '';

    const isPlusPlan = Object.values(PLUS_PLANS).includes(plan);

    useEffect(() => {
        if (router.pathname === '/purchase') {
            setLocalStorageItem(
                isPlusPlan ? CHECKOUT_URL.plusUrl : CHECKOUT_URL.premiumUrl,
                router.asPath,
            );

            // Removes localStorage references to image details (if there are any)
            removeLocalStorageItem(isPlusPlan ? SPID.mediaId : SID.mediaId);
            removeLocalStorageItem(isPlusPlan ? SPID.selectedSize : SID.selectedSize);
            removeLocalStorageItem(isPlusPlan ? SPID.selectedLicenseType : SID.selectedLicenseType);
            removeLocalStorageItem(isPlusPlan ? SPID.selectedPackage : SID.selectedPackage);
            removeLocalStorageItem(
                isPlusPlan ? SPID.selectedPriceDetails : SID.selectedPriceDetails,
            );
            removeLocalStorageItem(
                isPlusPlan ? SPID.showCheckoutModalBox : SID.showCheckoutModalBox,
            );
            removeLocalStorageItem(isPlusPlan ? SCMI.plusMediaId : SCMI.mediaId);
        }
    }, [router.pathname]);

    return {
        affMarketing: router?.query?.aff_marketing || props?.affMarketing || '',
        browser: props?.browser || {},
        cookies: cookies || {},
        countryOrigin: router?.query?.co || localeDetails?.country?.code || props?.countryOrigin,
        freeTrialBanner: parseInt(
            router?.query?.freeTrialBanner || props?.freeTrialBanner || 0,
            10,
        ),
        hostName: props?.hostName || '',
        selectedImageDetails: {},
        isServer: props.isServer,
        lang: props?.lang || '',
        locale: locale || props?.localeSSP,
        pageType: router?.pathname?.replace(/^\/(?:products\/)?(.+)$/, '$1'),
        pageOrigin,
        pkg,
        plan,
        autorenew,
        upgrade,
        addon,
        productType,
        proforma,
        promoCode: router?.query?.promocode || props?.promoCode || '',
        router: router || {},
        termsgrant,
        termtype,
        transid,
        AIImageDid: router?.query?.did || '',
        AIImageTid: router?.query?.tid || '',
        AIImageHeight: router?.query?.h || '',
        AIImageWidth: router?.query?.w || '',
        utmCampaign: router?.query?.utm_campaign || '',
    };
}

export const CommonDetailsProvider = (props) => {
    const { children } = props;
    return (
        <CommonDetailsContext.Provider value={useCommonDetails(props)}>
            {children}
        </CommonDetailsContext.Provider>
    );
};

CommonDetailsProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
