import axios from 'axios';
import { buildConfig } from 'helpers/axios';

const getTrafficLog = async ({ ctx, page, url, country }) => {
  try {
    const config = buildConfig(ctx);
    const apiUrl = `/apicore/tracking/traffic_log`;

    const postFields = {
      tracking_page: page,
      tracking_url: url,
      tracking_country: country,
    };

    const response = await axios.post(apiUrl, postFields, config);
    // console.log('getTrafficLog response:', response);
    return response.data;
  } catch (error) {
    // console.log('getTrafficLog error:', error);
    return null;
  }
};

export default getTrafficLog;
