import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function getWindowDimensions() {
    const width =
        document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    const height =
        document.documentElement.clientHeight || document.body.clientHeight || window.innerHeight;

    return {
        height,
        width,
        isMobileView: width < 768,
        isMobileXSView: width < 568,
        isMobileSMView: width >= 568 && width < 768,
        isTabletView: width >= 768 && width < 1200,
        isTabletMDView: width >= 768 && width < 992,
        isTabletLGView: width >= 992 && width < 1200,
        isDesktopView: width >= 1200,
        isDesktopXLView: width >= 1200 && width < 1440,
        isDesktopXXLView: width >= 1440,
    };
}

export default function useWindowDimensions({ deviceType }) {
    const mob = deviceType === 'mobile';
    const tab = deviceType === 'tablet';
    const desk = deviceType === 'desktop';

    // Context States
    const [windowDimensions, setWindowDimensions] = useState({
        height: mob ? 640 : tab ? 1024 : 800,
        width: mob ? 360 : tab ? 768 : 1200,
        isMobileView: mob,
        isMobileXSView: mob,
        isMobileSMView: false,
        isTabletView: tab,
        isTabletMDView: tab,
        isTabletLGView: false,
        isDesktopView: desk,
        isDesktopXLView: desk,
        isDesktopXXLView: false,
    });

    useEffect(() => {
        setWindowDimensions(getWindowDimensions());
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export const WindowDimensionContext = createContext();

export const WindowDimensionProvider = (props) => {
    const { children } = props;
    return (
        <WindowDimensionContext.Provider value={useWindowDimensions(props)}>
            {children}
        </WindowDimensionContext.Provider>
    );
};

export const withWindowDimension = (Component) => () => {
    <WindowDimensionContext.Consumer>
        {(props) => <Component {...props} />}
    </WindowDimensionContext.Consumer>;
};

WindowDimensionProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
