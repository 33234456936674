import nookies from 'nookies';

// Pre-defined Components
import { COOKIE_DOMAIN, COOKIE_PATH } from 'config/cookies';

/*
  Create New Cookie
*/
const createCookie = (ctx, cookieName, cookieValue, cookieMaxAge) => {
    nookies.set(ctx, cookieName, cookieValue, {
        path: COOKIE_PATH,
        domain: COOKIE_DOMAIN,
        maxAge: cookieMaxAge,
        sameSite: 'None',
        secure: true,
    });
};

export default createCookie;
