/**
 * Convert Date object to desired timezone
 *
 * @param Date date
 * @param String tzString https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 * @returns Date
 */
const convertTimeZone = (date, tzString) => {
    return new Date(
        (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
            timeZone: tzString,
        }),
    );
};

export default convertTimeZone;