import { AMERICA_NY_TZ as serverTimeZone } from 'config/constants';
import convertTimeZone from './convertTimeZone';
import formatDateDMYtoYMD from './formatDateDMYtoYMD';
import formatDateYMDtoTZFormat from './formatDateYMDtoTZFormat';
import formatDateToDMY from './formatDateToDMY';

/*
 * Convert to America/New_york timezone Date in DD/MM/YYYY format and return YYYY-MM-DD
 */
const getCurrentDateInAmericaTimeZone = (dateString) => {
    const currDate = convertTimeZone(
        formatDateYMDtoTZFormat(formatDateDMYtoYMD(dateString)),
        serverTimeZone,
    );

    return formatDateDMYtoYMD(formatDateToDMY(currDate));
};

export default getCurrentDateInAmericaTimeZone;
