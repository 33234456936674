const removeLocalStorageItem = (key) => {
    try {
        if (typeof window !== 'undefined' && localStorage.getItem(key) !== null) {
            window.localStorage.removeItem(key);
        }
    } catch (error) {
        // console.log("Error:", error);
    }
};

export default removeLocalStorageItem;