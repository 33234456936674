const getSiteLanguage = (hostName) => {
    switch (hostName) {
        case 'fr.123rf.com':
            return 'fr';
        case 'de.123rf.com':
            return 'de';
        case 'es.123rf.com':
            return 'es';
        case 'nl.123rf.com':
            return 'nl';
        case 'ru.123rf.com':
            return 'ru';
        case 'it.123rf.com':
            return 'it';
        case 'pl.123rf.com':
            return 'pl';
        case 'cz.123rf.com':
            return 'cz';
        case 'pt.123rf.com':
            return 'pt';
        case 'tr.123rf.com':
            return 'tr';
        case 'br.123rf.com':
            return 'br';
        case 'se.123rf.com':
            return 'se';
        case 'hu.123rf.com':
            return 'hu';
        case 'jp.123rf.com':
            return 'jp';
        case 'vn.123rf.com':
            return 'vn';
        case 'www.123rf.net':
        case 'gb.123rf.com':
            return 'gb';
        case 'www.123rf.co.kr':
            return 'kr';
        case 'kr.123rf.com':
            return 'kr';
        case 'tw.123rf.com':
            return 'tw';
        default:
            return 'en';
    }
};

export default getSiteLanguage;