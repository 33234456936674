import axios from 'axios';
import { buildConfig } from 'helpers/axios';

const getUserDetails = async (ctx = undefined) => {
    try {
        const config = buildConfig(ctx);

        const response = await axios.get('/apicore/members', config);
        // console.log('getUserDetails response:', response);
        return response.data.data;
    } catch (error) {
        // console.log('getUserDetails error:', error);
        return null;
    }
};

export default getUserDetails;
