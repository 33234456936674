/*
 * Convert String Date from DD/MM/YYYY to YYYY-MM-DD
 */
const formatDateDMYtoYMD = (strDate, separator='-') => {
  if (!strDate) return null;

  const [day, month, year] = strDate.split('/');
  
  return [year, month, day].join(separator);
};

export default formatDateDMYtoYMD;