import nookies from 'nookies';
import { listOfCountries } from '123rf-ui-core/config';

// Pre-defined Components
// import {
//     checkIsServer,
//     getBrowserAndDeviceType,
//     getHostName,
//     getSiteLanguage,
// } from 'helpers/server_side';
import {
    AFF_MARKETING,
    BUYING_CUSTOMER,
    BUYING_CUSTOMER_FIRST_PURCHASE,
    CHECKOUT_CART,
    CLIENT_IP_ADDRESS,
    COUNTRY_ORIGIN,
    FREE_TRIAL_BANNER,
    HOST_NAME,
    GLOBAL_LANG,
    MY_REGION,
    PACKAGE,
    PAGE_ORIGIN,
    PLAN,
    PLUS_CHECKOUT_CART,
    PROFORMA_HASH,
    PROMO_CODE,
    SAVED_ID,
    TERMSGRANT_HASH,
    TERM_TYPE,
    TRANS_ID,
} from 'config/cookies';
import {
    CHECKOUT_SUB_PATHS,
    DEFAULT_IP_ADDRESS,
    PAGE_FOLDERS,
    PAGE_ORIGINS,
    USER_PURCHASE_STATUS,
    VALID_PAGE_ORIGINS as validPOs,
} from 'config/constants';
import { PRODUCT_TYPES, PRODUCT_PACKAGE } from 'config/payments';
import createCookie from 'helpers/cookies/createCookie';
import checkIsServer from 'helpers/server_side/checkIsServer';
import getBrowserAndDeviceType from 'helpers/server_side/getBrowserAndDeviceType';
import getHostName from 'helpers/server_side/getHostName';
import getSiteLanguage from 'helpers/server_side/getSiteLanguage';

// APIs
import getUserBalance from 'api/queries/getUserBalance';
import getUserDetails from 'api/queries/getUserDetails';
import getUserLocale from 'api/queries/getUserLocale';

const initSSApp = async ({ ctx, router }) => {
    const isServer = checkIsServer(ctx);
    const browserDeviceType = getBrowserAndDeviceType(ctx);
    const { query } = isServer ? ctx : router || { query: {} };
    const cookies = isServer ? nookies.get(ctx) : nookies.get();
    const isTakePrecedence = !query.pkg && !query.plan;

    // API Data Constants
    let locale = null;
    let userBalance = null;
    let userDetails = null;

    // Global Constants
    let bc = parseInt(cookies[BUYING_CUSTOMER] || 0, 10);
    let bcfp = parseInt(cookies[BUYING_CUSTOMER_FIRST_PURCHASE] || 0, 10);
    const cyp = cookies[CHECKOUT_CART] || '';
    const cypp = cookies[PLUS_CHECKOUT_CART] || '';
    const clientIPAddress =
        ctx?.req?.headers['x-real-ip'] ||
        ctx?.req?.headers['x-forwarded-for']?.split(/, /)[0] ||
        cookies[CLIENT_IP_ADDRESS] ||
        DEFAULT_IP_ADDRESS;
    const hostName = getHostName(ctx) || cookies[HOST_NAME] || '';
    const lang = getSiteLanguage(hostName) || cookies[GLOBAL_LANG] || '';
    let userId = cookies[SAVED_ID] || '';
    let myRegion = cookies[MY_REGION] || '';
    const countryCode = myRegion.split('|')[0];

    // URL Param Constants
    const affMarketing = query?.aff_marketing || cookies[AFF_MARKETING] || '';
    const queryCo = (query?.co || '').toUpperCase();
    const isQueryCoValid = queryCo && Object.keys(listOfCountries).includes(queryCo);
    let co = (
        (isQueryCoValid ? queryCo : '') ||
        cookies[COUNTRY_ORIGIN] ||
        countryCode ||
        ctx?.req?.headers['cloudfront-viewer-country'] ||
        ''
    )?.toUpperCase(); // ex. co=MY/US
    const pkg = isServer ? query?.pkg || cookies[PACKAGE] || '' : query?.pkg || ''; // ex. pkg=subs/psub/credits
    const plan = isServer ? query?.plan || cookies[PLAN] || '' : query?.plan || ''; // ex. plan=demi1/tall1
    const proforma = !isServer ? '' : !isTakePrecedence ? '' : query?.proforma;
    const promoCode = query?.promocode || '';
    const freeTrialBanner = parseInt(query?.freeTrialBanner || 0, 10);
    const termsgrant = !isServer ? '' : !isTakePrecedence ? '' : query?.termsgrant;
    const termtype = !isServer ? 0 : !isTakePrecedence ? 0 : parseInt(query?.termtype, 10);
    const transid = query?.transid || cookies[TRANS_ID] || '';
    const po = (
        query?.po ||
        cookies[PAGE_ORIGIN] ||
        (proforma || (termsgrant && termtype) ? PAGE_ORIGINS.pricing : '')
    )?.toLowerCase(); // ex. po=pricing/details
    const productType = proforma
        ? PRODUCT_TYPES.proforma
        : termsgrant && termtype
            ? PRODUCT_TYPES.termsgrant
            : pkg && pkg !== PRODUCT_TYPES.multilogin && plan && po && validPOs.includes(po)
                ? PRODUCT_TYPES.cancellation
                : pkg === PRODUCT_PACKAGE.misc &&
                    plan === PRODUCT_TYPES.cancellation &&
                    transid &&
                    po &&
                    validPOs.includes(po)
                    ? PRODUCT_TYPES.default
                    : pkg === PRODUCT_TYPES.multilogin && transid && plan && po && validPOs.includes(po)
                        ? PRODUCT_TYPES.multilogin
                        : '';

    // Get All App Dependencies
    if (isServer) {
        [locale, userDetails, userBalance] = await Promise.all([
            getUserLocale(ctx, co),
            getUserDetails(ctx) || {},
            getUserBalance(ctx) || { credits: 0, subs: 0, psub: 0, plus: 0 },
        ]);
        locale.ipAddress = DEFAULT_IP_ADDRESS;
        co = locale?.country?.code || co;
        myRegion = locale?.country
            ? `${locale?.country?.code}|${locale?.country?.description}`
            : myRegion;
        bc = userDetails?.personal_details?.purchased_status
            ? USER_PURCHASE_STATUS.buyer
            : USER_PURCHASE_STATUS.nonBuyer;
        userId = userDetails?.personal_details?.uid || userId;
    }

    // Set Global Constant Cookies
    bc && createCookie(ctx, BUYING_CUSTOMER, bc);
    bc && createCookie(ctx, BUYING_CUSTOMER_FIRST_PURCHASE, bc, 60 * 60 * 24 * 365 * 5);
    co && createCookie(ctx, COUNTRY_ORIGIN, co || locale?.country?.code);
    cyp && createCookie(ctx, CHECKOUT_CART, cyp);
    cypp && createCookie(ctx, PLUS_CHECKOUT_CART, cypp);
    clientIPAddress && createCookie(ctx, CLIENT_IP_ADDRESS, clientIPAddress);
    hostName && createCookie(ctx, HOST_NAME, hostName);
    lang && createCookie(ctx, GLOBAL_LANG, lang);
    myRegion && createCookie(ctx, MY_REGION, myRegion, 60 * 60 * 24 * 14);
    userId && createCookie(ctx, SAVED_ID, userId);

    // Set Checkout URL Param Constant Cookies
    if (
        (isServer &&
            ctx.pathname.includes(`/${PAGE_FOLDERS.checkout}`) &&
            !CHECKOUT_SUB_PATHS.includes(ctx.pathname)) ||
        (router?.pathname?.includes(`/${PAGE_FOLDERS.checkout}`) &&
            !CHECKOUT_SUB_PATHS.includes(router?.pathname))
    ) {
        affMarketing && createCookie(ctx, AFF_MARKETING, affMarketing);
        pkg && createCookie(ctx, PACKAGE, pkg);
        plan && createCookie(ctx, PLAN, plan);
        po && createCookie(ctx, PAGE_ORIGIN, po);
        proforma && createCookie(ctx, PROFORMA_HASH, proforma);
        createCookie(ctx, PROMO_CODE, promoCode);
        createCookie(ctx, FREE_TRIAL_BANNER, freeTrialBanner);
        termsgrant && createCookie(ctx, TERMSGRANT_HASH, termsgrant);
        termtype && createCookie(ctx, TERM_TYPE, termtype);
        transid && createCookie(ctx, TRANS_ID, transid);
    }

    return {
        /* API Data Constants */
        locale,
        userBalance,
        userDetails,
        /* Global Constants */
        ...browserDeviceType,
        bc,
        clientIPAddress,
        hostName,
        lang,
        userId,
        /* URL Param Constants */
        affMarketing,
        countryOrigin: co,
        freeTrialBanner,
        pkg,
        plan,
        pageOrigin: po,
        productType,
        proforma,
        promoCode,
        termsgrant,
        termtype,
        transid,
    };
};

export default initSSApp;
