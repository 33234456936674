import Router from 'next/router';

// Pre-defined Components
import { PAGE_FOLDERS } from 'config/constants';
// import { updateCheckoutURLParams } from 'helpers/client_side';
import initSSApp from 'helpers/server_side/initSSApp';

const initCSApp = async (props) => {
    const { router } = props;

    // Remove splan param from URL
    // if (router?.query?.splan) {
    //     const checkoutParams = updateCheckoutURLParams(router?.query);
    //     router.replace(`/${PAGE_FOLDERS.checkout}/${checkoutParams}`, undefined, {
    //         shallow: true,
    //     });
    // }

    Router.beforePopState((cb) => {
        const { url, as } = cb;
        if (as && as !== undefined) {
            Router.push(url, as);
        } else {
            const { pathname } = window.location;
            Router.push(pathname, pathname, { shallow: true });
        }
    });

    await initSSApp({ ctx: null, router });
};

export default initCSApp;
