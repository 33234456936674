import axios from 'axios';
import { buildConfig } from 'helpers/axios';

const getCreditsDetails = async ({ ctx }) => {
    try {
        const config = buildConfig(ctx);
        const url = `/apicore-payments/tracking/credits-details`;

        const response = await axios.get(url, config);
        // console.log('getCreditsDetails response:', response);
        return response.data;
    } catch (error) {
        // console.log('getCreditsDetails error:', error);
        return null;
    }
};

export default getCreditsDetails;
