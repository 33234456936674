import { useContext } from 'react';
import { GoogleTagManagerContext } from 'contexts/GoogleTagManager';

function Pixelator() {
    const { pixelators } = useContext(GoogleTagManagerContext);

    if (!pixelators) { 
        return null;
    }

    return (
        <div
            id="pixelators_tl"
            style={{
                display: 'none',
            }}
        >
            <img
                alt="pixelator_img"
                height={1}
                width={1}
                style={{ border: 0 }}
                src={`https://bdt.123rf.com/tk/?${pixelators}`}
            />
        </div>
    );
}

export default Pixelator;
