import axios from 'axios';
import { buildConfig } from 'helpers/axios';

const getUserLocale = async (ctx = undefined, countryOrigin) => {
    try {
        const config = buildConfig(ctx);
        const param = countryOrigin ? `?co=${countryOrigin}` : '';
        const url = `/apicore/locale${param}`;

        const response = await axios.get(url, config);
        // console.log('getUserLocale response:', response);
        return response.data.data;
    } catch (error) {
        // console.log('getUserLocale error:', error);
        return null;
    }
};

export default getUserLocale;
