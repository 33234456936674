const getBrowserAndDeviceType = (ctx) => {
    const userAgent = ctx?.req?.headers['user-agent'] || navigator?.userAgent || '';
    const mb =
        userAgent.match(/Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i)?.length >
        0;
    const tb = userAgent.match(/Tablet|iPad/i)?.length > 0;

    return {
        browser: {
            isEdge: userAgent.match(/Edg/i)?.length > 0,
            isFirefox: userAgent.match(/Firefox/i)?.length > 0,
            isIE: userAgent.match(/Trident/i)?.length > 0,
            isOpera: userAgent.match(/OPR/i)?.length > 0,
        },
        deviceType: tb ? 'tablet' : mb ? 'mobile' : 'desktop',
    };
};

export default getBrowserAndDeviceType;