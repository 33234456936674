// Cookie Options
export const COOKIE_PATH = '/';
export const COOKIE_DOMAIN = '.123rf.com';
export const COOKIE_MAXAGE = {
    thirtyMins: 1800, // 30 mins
    oneHour: 3600, // 1 hour
    twoHours: 7200, // 2 hours
};

// Global Cookies
export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';
export const BUYING_CUSTOMER = 'bc';
export const BUYING_CUSTOMER_FIRST_PURCHASE = 'bcfp'; // NEW50 promo
export const CHECKOUT_CART = 'cyp';
export const PLUS_CHECKOUT_CART = 'cypp';
export const COUNTRY_ORIGIN = 'country2';
export const GLOBAL_LANG = 'global_lang';
export const CLIENT_IP_ADDRESS = 'usr_ipa';
export const HOST_NAME = 'global_host';
export const MY_REGION = 'myregion';
export const KEEP_USER = 'keep_user';
export const LOCALE_INFO = 'localeinfo';
export const LOGIN_DONE = 'logindone';
export const PHPSESSID = 'PHPSESSID';
export const SAVED_ID = 'savedid';
export const USER_UID = 'usr_uid';
export const USER_LOGIN = 'usr_lgn';
export const USER_NAME = 'global_user';
export const USER_VERSION = 'usr_ver';

// Checkout URL Params Cookies
export const CHECKOUT_URL = { premiumUrl: '__curl', plusUrl: '__pcurl' };
export const FREE_TRIAL_BANNER = '__ftb';
export const IS_PROFORMA = '__ip';
export const IS_TERMSGRANT = '__it';
export const IS_PENALTY = '__ipen';
export const PACKAGE = '__pkg';
export const PAGE_ORIGIN = '__po';
export const PLAN = '__plan';
export const PROMO_CODE = '__pc';
export const PROFORMA_HASH = '__prh';
export const TERMSGRANT_HASH = '__tgh';
export const TERM_TYPE = '__tt';
export const TRANS_ID = '__ti';
export const CHECKOUTLOG_ID = '__cli';
export const MULTILOGIN_PLAN_TYPE = '__mpt';

// Payment Method Details Cookies
export const PAYMENT_METHODS_COOKIES = {
    selectedPaymentMethod: '__pm_spm',
    holderName: '__pm_hm',
    encryptedCardNumber: '__pm_ecm',
    encryptedExpiryMonth: '__pm_eem',
    encryptedExpiryYear: '__pm_eey',
    encryptedExpiryDate: '__pm_eed',
    encryptedSecurityCode: '__pm_esc',
    adyenConfigured: '__pm_ac',
    selectedCard: '__pm_sc',
    setupAddNewCardForm: '__pm_sancf',
    inicisSelectedPaymentMethod: '__pm_ispm',
};

// Fapiao Details Cookies
export const FAPIAO_DETAILS_COOKIES = {
    selectedUniformInvoiceType: '__fp_suit',
    businessEntitiesCompanyName: '__fp_becn',
    businessEntitiesCompanyRegNum: '__fp_becrn',
    carrierDeviceMemberCode: '__fp_cdmc',
    donateMultiCarrier: '__fp_dmc',
    donateLoveCode: '__fp_dlc',
    donateCarrierId1: '__fp_cid1',
    donateCarrierId2: '__fp_cid2',
    donateCompanyName: '__fp_dcn',
    donateCompanyRegNumber: '__fp_dcrn',
};

// Billing Details Cookies
export const BILLING_DETAILS_COOKIES = {
    firstName: '__bd_fn',
    lastName: '__bd_ln',
    email: '__bd_eml',
    phoneNumber: '__bd_phn',
    address: '__bd_addr',
    city: '__bd_cty',
    zipCode: '__bd_zip',
    state: '__bd_st',
    country: '__bd_cnty',
};

// Company Details Cookies
export const COMPANY_DETAILS_COOKIES = {
    companyName: '__cd_cn',
    companyRegistrationNumber: '__cd_crn',
    jobReference: '__cd_jr',
    vatNumber: '__cd_vn',
};

// Subscription Activation Date States
export const SUBSCRIPTION_ACTIVATION_COOKIES = {
    subscribeOption: '__sa_so',
    subscribeDate: '__sa_dt',
};

// Newly Signed up Account (Y=Yes,N=No)
export const NEWLY_SIGNUP_ACCOUNT = '__nsa';

// Adyen Payment Successful (Y=Yes,N=No)
export const ADYEN_PAYMENT_SUCCESS = '__aps';

// Inicis Payment Successful (Y=Yes,N=No)
export const INICIS_PAYMENT_SUCCESS = '__ips';

// Paypal Redirected to Payment Portal (Y=Yes,N=No)
export const PAYPAL_REDIRECT_PORTAL = '__prp';

// Tracking Cookies
export const TRACKING_DATA_COOKIES = {
    gtmServer: '__td_gtms',
    hasPurchased: '__td_hp',
    orderId: '__td_oi',
    promocode: '__td_p',
    subscriptionId: '__td_si',
    totalTrx: '__td_tt',
    transid: '__td_ti',
    usdPrice: '__td_up',
};

// Aff_marketing (shareasale) cookie
export const AFF_MARKETING = 'aff_marketing';

// GTM Transaction Done Information Cookie
export const TRANS_DONE_INFO = '__tdi';

// GTM Transaction Fail Information Cookie
export const TRANS_FAIL_INFO = '__tfi';

// Selected Image Detail Cookies
export const SELECTED_IMAGE_DETAIL_COOKIES = {
    mediaId: '__sid_mid',
    selectedSize: '__sid_sz',
    selectedLicenseType: '__sid_lt',
    selectedPackage: '__sid_pkg',
    selectedPriceDetails: '__sid_pd',
    showCheckoutModalBox: '__sid_scm',
    showCheckoutSuccessModal: '__sid__scsm',
    showCheckoutUnsuccessModal: '__sid__scusm',
};

// Selected Plus Image Detail Cookies
export const SELECTED_PLUS_IMAGE_DETAIL_COOKIES = {
    mediaId: '__spid_mid',
    selectedSize: '__spid_sz',
    selectedLicenseType: '__spid_lt',
    selectedPackage: '__spid_pkg',
    selectedPriceDetails: '__spid_pd',
    showCheckoutModalBox: '__spid_scm',
    showCheckoutSuccessModal: '__spid__scsm',
    showCheckoutUnsuccessModal: '__spid__scusm',
};

// CYP Selected Media Id
export const SELECTED_CYP_MEDIA_ID = {
    mediaId: '__cyp_mid',
    plusMediaId: '__cyp_pmid',
};

// AI Image Detail Cookies
export const SELECTED_AI_IMAGE_DETAILS_COOKIES = {
    imageTid: '__said_tid',
    imageDid: '__said_did',
};
