import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { getCoreIntl } from '123rf-ui-core/utils';
import { LocaleProvider, UserProvider, UICoreGrowthbookProvider } from '123rf-ui-core/contexts';
import dynamic from 'next/dynamic';
import getConfig from 'next/config';
import intl from 'react-intl-universal';
import nookies from 'nookies';
import PropTypes from 'prop-types';
import { GrowthBook } from '@growthbook/growthbook-react';
import { getUICoreServerSideGrowthBookContext } from '123rf-ui-core/config';

// Pre-defined Components
import { PAGE_FOLDERS as pageFolders } from 'config/constants';
import checkIsServer from 'helpers/server_side/checkIsServer';
import initCSApp from 'helpers/client_side/initCSApp';
import initIntlSSR from 'helpers/server_side/initIntlSSR';
import initSSApp from 'helpers/server_side/initSSApp';

// Context Providers
import { CommonDetailsProvider } from 'contexts/CommonDetails';
import { GoogleTagManagerProvider } from 'contexts/GoogleTagManager';
import { ProgressProvider } from 'contexts/Progress';
import { WindowDimensionProvider } from 'contexts/WindowDimension';

// Sub Components
import AppHead from 'components/Shared/AppHead';
import Pixelator from 'components/Shared/Pixelator';
import RelatedHrefLang from 'components/Shared/RelatedHrefLang';

// Global Styles
import '123rf-ui-core/main.css';
import 'styles/inicis_bootstrap.min.css';
import 'styles/global.scss';

// Loadable Components
const CheckoutCSS = dynamic(() => import('components/Shared/CheckoutCSS'));

// Enable if want to monitor web vitals
// export function reportWebVitals(metric) {
//     console.log(metric);
// }

const MainApp = ({ Component, pageProps, ...props }) => {
    const {
        countryOrigin,
        deviceType,
        lang,
        router,
        featureFlags,
        userBalance: userBalanceSSP,
        userDetails: userDetailsSSP,
    } = props;

    // Initialize Localization
    initIntlSSR({ ...props });

    useEffect(() => {
        initCSApp({ router, lang });
    }, [router.pathname]);

    useEffect(() => {
        const { publicRuntimeConfig } = getConfig();
        const datadogEnv = process.env.NEXT_PUBLIC_DATADOG_ENVIRONMENT || 'dev';

        if (datadogEnv !== 'dev') {
            // RUM Browser Monitoring
            datadogRum.init({
                applicationId: process.env.NEXT_PUBLIC_DATADOG_APP_ID,
                clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
                site: 'datadoghq.com',
                service: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
                allowedTracingUrls: ['https://123rf.com', /https:\/\/.*\.123rf\.com/],
                env: datadogEnv,
                version: publicRuntimeConfig?.version,
                sessionSampleRate: 100,
                sessionReplaySampleRate: 100,
                trackResources: true,
                trackLongTasks: true,
                trackUserInteractions: true,
                defaultPrivacyLevel: 'mask-user-input',
                silentMultipleInit: true,
            });
        }
    }, []);

    return (
        <>
            <AppHead {...props} pageProps={pageProps} />
            <RelatedHrefLang lang={lang} />
            {router.pathname.includes(`/${pageFolders.checkout}`) && <CheckoutCSS />}

            <UICoreGrowthbookProvider envKey={process.env.APP_ENV} SSRfeatureFlags={featureFlags}>
                <WindowDimensionProvider deviceType={deviceType}>
                    <ProgressProvider>
                        <LocaleProvider countryCode={countryOrigin} intl={intl} lang={lang}>
                            <UserProvider
                                fetchUser={false}
                                onUserLogin={() => {}}
                                ssrUserBalance={
                                    userBalanceSSP || { credits: 0, subs: 0, psub: 0, plus: 0 }
                                }
                                ssrUserDetails={userDetailsSSP}
                            >
                                <CommonDetailsProvider {...props}>
                                    <GoogleTagManagerProvider>
                                        <Component {...pageProps} />
                                        <Pixelator />
                                    </GoogleTagManagerProvider>
                                </CommonDetailsProvider>
                            </UserProvider>
                        </LocaleProvider>
                    </ProgressProvider>
                </WindowDimensionProvider>
            </UICoreGrowthbookProvider>
        </>
    );
};

MainApp.getInitialProps = async ({ Component, ctx }) => {
    const initSSProps = await initSSApp({ ctx, router: null });
    const isServer = checkIsServer(ctx);
    const cookies = isServer ? nookies.get(ctx) : nookies.get();

    const pageProps = Component.getInitialProps
        ? await Component.getInitialProps({
              ctx,
              cookies,
              ...initSSProps,
          })
        : {};

    const pagePath = pageProps?.pagePath || pageFolders.checkout;
    const mergedLocales = {
        ...(await import(`locales/${pagePath}/${initSSProps?.lang || 'en'}`)).default, // Pricing or Payments
        ...(await import(`locales/shared/${initSSProps?.lang || 'en'}`)).default, // Shared
        ...(await getCoreIntl(initSSProps?.lang || 'en')).default, // UI Core
    };

    // Growthbook feature flag SSR
    const UICoregrowthbookContext = getUICoreServerSideGrowthBookContext(process.env.APP_ENV);
    const UICoreGrowthbook = new GrowthBook(UICoregrowthbookContext);
    await UICoreGrowthbook.loadFeatures({ timeout: 1000 });

    return {
        isServer,
        pageProps,
        mergedLocales,
        ...initSSProps,
        featureFlags: UICoreGrowthbook,
    };
};

MainApp.propTypes = {
    Component: PropTypes.func.isRequired,
    pageProps: PropTypes.objectOf(PropTypes.shape).isRequired,
    userDetails: PropTypes.objectOf(PropTypes.shape),
    userBalance: PropTypes.objectOf(PropTypes.shape),
    router: PropTypes.objectOf(PropTypes.shape).isRequired,
    lang: PropTypes.string.isRequired,
    affMarketing: PropTypes.string,
    countryOrigin: PropTypes.string.isRequired,
    freeTrialBanner: PropTypes.number,
    pkg: PropTypes.string.isRequired,
    plan: PropTypes.string.isRequired,
    pageOrigin: PropTypes.string.isRequired,
    productType: PropTypes.string.isRequired,
    proforma: PropTypes.string,
    promoCode: PropTypes.string,
    hostName: PropTypes.string.isRequired,
    isServer: PropTypes.bool.isRequired,
    browser: PropTypes.objectOf(PropTypes.shape),
    featureFlags: PropTypes.objectOf(PropTypes.shape),
    deviceType: PropTypes.string,
    termsgrant: PropTypes.string,
    termtype: PropTypes.number,
    transid: PropTypes.string,
};

MainApp.defaultProps = {
    affMarketing: '',
    browser: {
        isEdge: false,
        isFirefox: false,
        isIE: false,
        isOpera: false,
    },
    deviceType: '',
    freeTrialBanner: 0,
    proforma: '',
    promoCode: '',
    termsgrant: '',
    termtype: 0,
    transid: '',
    userDetails: null,
    userBalance: null,
    featureFlags: {},
};

export default MainApp;
