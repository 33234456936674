import checkIsServer from 'helpers/server_side/checkIsServer';

export function baseURL(ctx = undefined) {
    const isServer = checkIsServer(ctx);

    return isServer ? `${process.env.API_HOST_SSR}` : '/';
}

export function buildConfig(ctx) {
    const isServer = checkIsServer(ctx);
    const config = {
        baseURL: baseURL(ctx),
        headers: {},
        withCredentials: true,
    };

    if (isServer) {
        const headerCookie = ctx?.req?.headers?.cookie;

        if (headerCookie && headerCookie !== null && headerCookie !== undefined) {
            config.headers = {
                cookie: headerCookie,
            };
        }
    }

    return config;
}
