/* eslint-disable react/display-name */
// https://github.com/zeit/next.js/tree/master/examples/with-loading
import { createContext } from 'react';
import NProgress from 'nprogress';
import PropTypes from 'prop-types';
import Router from 'next/router';

const NPROGRESS = {
    isLoadingRoute: 0.3,
    isLoadingPage: 0.5,
};

NProgress.configure({ showSpinner: false });

Router.events.on('routeChangeStart', () => {
    NProgress.set(NPROGRESS.isLoadingRoute);
    NProgress.start();
});
Router.events.on(
    'routeChangeComplete',
    () => NProgress.status !== NPROGRESS.isLoadingPage && NProgress.done(),
);
Router.events.on('routeChangeError', () => NProgress.done());

function useProgress() {
    return {
        loading: () => {
            NProgress.set(NPROGRESS.isLoadingPage);
            NProgress.start();
        },
        done: NProgress.done,
    };
}

export const ProgressContext = createContext();
export const ProgressProvider = ({ children }) => {
    return <ProgressContext.Provider value={useProgress()}>{children}</ProgressContext.Provider>;
};

export const withProgress = (Component) => () => (
    <ProgressContext.Consumer>{(props) => <Component {...props} />}</ProgressContext.Consumer>
);

ProgressProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
