import checkIsServer from './checkIsServer';

const getHostName = (ctx) => {
    return checkIsServer(ctx)
        ? ctx?.req?.headers?.host
            ? ctx.req.headers.host.split(':')[0]
            : 'www.123rf.com'
        : window.location.hostname || '';
};

export default getHostName;