/*
 *  Convert String Date from YYYY-MM-DD to YYYY-MM-DD HH:mm:ss
 *  To get current datetime of the browser
 */
const formatDateYMDtoTZFormat = (strDate) => {
  const date = new Date();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const timeString = `${hours}:${minutes}:${seconds}`;

  return `${strDate} ${timeString}`;
};

export default formatDateYMDtoTZFormat;