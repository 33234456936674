const setLocalStorageItem = (key, value) => {
    try {
        if (typeof window !== 'undefined') {
            window.localStorage.setItem(key, value);
        }
    } catch (error) {
        // console.log("Error:", error);
    }
};

export default setLocalStorageItem;