import { Fragment } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import PropTypes from 'prop-types';

// Pre-defined Components
import { SUPPORTED_HREF_LANGS as languageList } from 'config/global';

function RelatedHrefLang({ lang }) {
    const { asPath, pathname } = useRouter();
    const baseUrl = '123rf.com';

    if (['/_error', '/404', '/500'].indexOf(pathname) >= 0) return null;

    return (
        <Head>
            <link
                key={`${lang}-canonical`}
                rel="canonical"
                href={`https://${lang === 'en' ? 'www' : lang}.${baseUrl}${asPath}`}
            />
            {languageList.map((language) => {
                if (language.lang === 'en') {
                    const hrefEng = `https://www.${baseUrl}${asPath}`;
                    return (
                        <Fragment key="en-lang">
                            <link
                                key={`${language.lang}-x-default`}
                                rel="alternate"
                                href={hrefEng}
                                hrefLang="x-default"
                            />
                            <link
                                key={`${language.lang}-alternate`}
                                rel="alternate"
                                href={hrefEng}
                                hrefLang={language.hrefLang}
                            />
                        </Fragment>
                    );
                }
                return (
                    <link
                        key={`${language.lang}-alternate`}
                        rel="alternate"
                        href={`https://${language.lang}.${baseUrl}${asPath}`}
                        hrefLang={language.hrefLang}
                    />
                );
            })}
        </Head>
    );
}

RelatedHrefLang.propTypes = {
    lang: PropTypes.string.isRequired,
};

export default RelatedHrefLang;
