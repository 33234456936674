export const APP_NAME = '123RF';

// Support Languages
export const SUPPORTED_HREF_LANGS = [
    { lang: 'br', hrefLang: 'br' },
    { lang: 'cz', hrefLang: 'cs' },
    { lang: 'de', hrefLang: 'de' },
    { lang: 'en', hrefLang: 'en' },
    { lang: 'es', hrefLang: 'es' },
    { lang: 'fr', hrefLang: 'fr' },
    { lang: 'gb', hrefLang: 'zh-Hans' },
    { lang: 'hu', hrefLang: 'hu' },
    { lang: 'it', hrefLang: 'it' },
    { lang: 'jp', hrefLang: 'ja' },
    { lang: 'kr', hrefLang: 'ko' },
    { lang: 'nl', hrefLang: 'nl' },
    { lang: 'pl', hrefLang: 'pl' },
    { lang: 'pt', hrefLang: 'pt' },
    { lang: 'ru', hrefLang: 'ru' },
    { lang: 'tr', hrefLang: 'tr' },
    { lang: 'tw', hrefLang: 'zh-Hant' },
];
