const getContentGroup = ({ pageType }) => {
    switch (pageType) {
        case 'products':
            return '11';
        case 'purchase':
            return '22';
        default:
            return '17';
    }
};

export default getContentGroup;