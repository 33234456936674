import intl from 'react-intl-universal';

const initIntlSSR = async (props) => {
    const { lang, mergedLocales } = props;

    const currentLocale = lang;
    
    intl.init({
        currentLocale,
        locales: {
            [currentLocale]: mergedLocales,
        },
    });
};

export default initIntlSSR;
