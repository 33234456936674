import axios from 'axios';
import { buildConfig } from 'helpers/axios';

const getUserBalance = async (ctx = undefined) => {
    try {
        const config = buildConfig(ctx);

        const response = await axios.get('/apicore/members/balance', config);
        // console.log('getUserBalance response:', response);
        return response.data === 'Unauthorized.' ? null : response.data;
    } catch (error) {
        // console.log('getUserBalance error:', error);
        return null;
    }
};

export default getUserBalance;
