import { createContext, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { UserContext } from '123rf-ui-core/contexts';
import md5 from 'md5';
import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module';

// Contexts
import { CommonDetailsContext } from 'contexts/CommonDetails';

// Pre-defined Components
import { A2M_COUNTRIES as a2mCountries } from 'config/constants';
import getContentGroup from 'helpers/client_side/getContentGroup';
import getPageType from 'helpers/client_side/getPageType';

// APIs
import getTrafficLog from 'api/queries/getTrafficLog';
import getCreditsDetails from 'api/queries/getCreditsDetails';

export const GoogleTagManagerContext = createContext();

export function useGoogleTagManager() {
    const CommonDetails = useContext(CommonDetailsContext);
    const { User } = useContext(UserContext);
    const { countryOrigin } = CommonDetails;
    const { userId, userIsLoggedIn } = User;

    const gtmSessionId = md5(userId);
    const { asPath, pathname } = useRouter();

    const STATUS_LOGGED_IN = 'logged_in';
    const CONTENT_GROUP1 = {
        signupModal: '2',
        signinModal: '3',
        pricingPage: '11',
        checkoutPaymentPage: '22',
    };

    // Local States
    const [initializedGTM, setInitializedGTM] = useState(false);
    const [pixelators, setPixelators] = useState('');
    const [userCredits, setUserCredits] = useState(null);

    /*
        Begin - Shared Methods
    */
    const pushGTMData = (props) => TagManager.dataLayer({ dataLayer: { ...props } });
    const pushUserCreditsDetails = async () => {
        if (userIsLoggedIn) {
            const credits = await getCreditsDetails({ ctx: {} });
            pushGTMData({
                credits_expiry: credits?.credits_expiry || '0000-00-00 00:00:00',
                remaining_credits_footer: credits?.remaining_credits || '0',
            });
            setUserCredits(credits);
        }
    };
    /*
        End - Shared Methods
    */

    useEffect(() => {
        let mounted = true;
        let gtmDidInit = false;

        const initGTM = async () => {
            if (
                !['/purchase/ideal', '/purchase/threeds', '/purchase/paypal_capture'].includes(
                    pathname,
                ) &&
                !gtmDidInit
            ) {
                gtmDidInit = true;
                const trafficData = await getTrafficLog({
                    ctx: {},
                    page: getPageType({ pathname }),
                    url: window.location.href,
                    country: countryOrigin,
                });

                if (trafficData?.status) {
                    const dataLayer = {
                        ...(!initializedGTM && { isVirtualPage: 1 }),
                        content_group_1: getContentGroup({
                            pageType: getPageType({ pathname }),
                        }),
                        // react: true,
                        event: '123rf_bdtkt',
                        '123rf_bdtkpl': trafficData.encrypted_traffic_data,
                    };

                    if (initializedGTM) {
                        pushGTMData({ ...dataLayer });
                    } else {
                        TagManager.initialize({
                            gtmId: process.env.GTM_ID,
                            dataLayer,
                        });
                    }
                }

                pushUserCreditsDetails();

                if (mounted) {
                    setPixelators(trafficData?.encrypted_traffic_data || '');
                    setInitializedGTM(true);
                }
            }
        };

        if (initializedGTM) {
            initGTM();
        } else {
            const initGTMOnEvent = (event) => {
                initGTM();
                event.currentTarget.removeEventListener(event.type, initGTMOnEvent);
            };

            const initTimer = setTimeout(initGTM, 5000);
            document.addEventListener('scroll', initGTMOnEvent);
            document.addEventListener('mousemove', initGTMOnEvent);
            document.addEventListener('touchstart', initGTMOnEvent);

            return () => {
                initTimer && clearTimeout(initTimer);
                document.removeEventListener('scroll', initGTMOnEvent);
                document.removeEventListener('mousemove', initGTMOnEvent);
                document.removeEventListener('touchstart', initGTMOnEvent);
            };
        }

        return () => {
            mounted = false;
        };
    }, [asPath]);

    const getUIDHashCode = (uid) => md5(uid);

    const STATUS_SESSION_ID = userIsLoggedIn
        ? {
              status: STATUS_LOGGED_IN,
              sessionID: gtmSessionId,
          }
        : {};

    return {
        contentGroup1: CONTENT_GROUP1,
        statusSessionId: STATUS_SESSION_ID,
        googleAdsConversionLabel: process.env.GOOGLE_ADS_CONVERSION_LABEL,
        initializedGTM,
        pixelators,
        a2mCountries,
        userCredits,
        getUIDHashCode,
        pushGTMData,
    };
}

export const GoogleTagManagerProvider = (props) => {
    const { children } = props;
    return (
        <GoogleTagManagerContext.Provider value={useGoogleTagManager(props)}>
            {children}
        </GoogleTagManagerContext.Provider>
    );
};

GoogleTagManagerProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default GoogleTagManagerProvider;
