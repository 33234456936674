const getPageType = ({ pathname }) => {
    switch (pathname) {
        case '/products':
            return 'products';
        case '/purchase/payment_status':
            return 'payment_status';
        case '/purchase/threeds':
            return 'threeds';
        case '/purchase/paypal_capture':
            return 'paypal_capture';
        default:
            return 'purchase';
    }
};

export default getPageType;
